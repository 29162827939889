@import "../../assets/sass/fonts.scss";
@import "../../assets/sass/theme.scss";

@media (max-width: 750px) {
  .ad-file-upload-text {
    position: absolute;
    font-size: 12px !important;
    top: 47px;
    left: 15% !important;
    z-index: 1;
  }
}

@media only screen and (min-width: 1300px) {
  .ad_dash_img_card_outer-cont {
    width: 84%;
  }

  .wid_lg_99 {
    width: 99%;
  }
}

@media (max-width: 1000px) {
  .ad_dash_img_card_outer-cont {
    width: 100%;
  }
}

@media (min-width: 1000px) and (max-width: 1300px) {
  .ad_dash_img_card_outer-cont {
    width: 77%;
  }
  .wid_lg_99 {
    width: 99%;
  }
}

@media (min-width: 700px) and (max-width: 1130px) {
  .mid-sc-res {
    width: 90% !important;
  }
}

@media (max-width: 1146px) {
  .dash-btm-cards-cont {
    width: 100% !important;
  }

  .m-r-14px {
    margin-right: 0 !important;
  }
}

@media (max-width: 450px) {
  // .ad-file-upload {
  //   width: 120% !important;
  // }
  .admin-create-bc-cont {
    width: 100% !important;
    margin: 0 25px !important;
  }
}

.t3 {
  color: $t3;
}

.t6 {
  color: $t6;
}

// font

.fnt-fam-f1 {
  font-family: "f1";
}
.fnt-fam-f2 {
  font-family: "f2" !important;
}

.fnt-fam-f3 {
  font-family: "f3" !important;
}

.no-apprence {
  appearance: none;
}

.ad-file-upload-text {
  position: absolute;
  font-size: 13px;
  top: 47px;
  left: 30%;
  z-index: 1;
}

.ad-file-upload {
  .lkjDVC .sc-dkrFOg {
    padding: 20px;
  }
}

.pad-20px {
  padding: 20px;
}

.over-flow-x-scroll {
  overflow-x: scroll;
}

.dashbord-card-cont {
  min-width: 500px;
  // max-width: 770px;
  background-color: #ffff;
  box-shadow: 0px 0px 8px -1px #c6c6c6;
}

.dash-choice-active {
  color: #247395;
  text-align: center;
  border-bottom: 2px solid #247395;
}
.border-btm {
  border-bottom: 1px solid #dbdbdb;
}

.wid-141px {
  width: 141px;
}

.m-r-14px {
  margin-right: 32px;
}

.otp-modal .modal-content {
  padding: 15px;
  .sup-modal {
    .fav-icon {
      width: 40px;
      .img_tick {
        img {
          width: 35px;
        }
      }
    }
  }
}

.modal-content {
  // background-color: transparent !important;
  margin: auto !important;
  border: 0px solid transparent !important;
  .sup-modal {
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    border: 0;
    width: 350px;
    margin: auto;
    .fav-icon {
      width: 50px;
    }
    .img_tick {
      img {
        width: 23px;
      }
      h5 {
        font-size: 14px;
        margin-bottom: 0;
        font-family: "f3";
      }
      p {
        font-size: 14px;
      }
    }
  }
}

.fs-14px {
  font-size: 14px !important;
}

.fs-15px {
  font-size: 15px;
}

.dash-card-choice-cont {
  width: 110px;
  margin-right: 23px;
}

.wid-92 {
  width: 92%;
  margin: auto;
}

.dash-admin-div-wid {
  min-width: 185px;
  max-width: 223px;
}

.ad-bc-list-btn {
  border-radius: 2px;
  padding: 10px 14px;
  margin: 5px 0;
  max-width: 123px;
  min-width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-light-acqu {
  background-color: #e6fff8;
}

.bg-light-green {
  background-color: #59d559;
}

.ad-bc-table-btn {
  width: 70px;
  height: 23px;
  font-family: "f2";
  align-items: center;
  justify-content: center;
  display: flex;
}

.light-green-bg {
  background-color: #9ad258;
}

.ad-bc-tb-dropdown {
  position: relative;
  border-radius: 0.2rem;
  width: 87px;
  height: 83px;
  font-family: "f2";
  left: 8px;
  // right: 28px;
  background: #3c3c3c;
  color: #fff;
  font-size: 13px;
}

.rg-footer {
  .fot_des a,
  .fot_des span {
    padding: 0 5px;
    color: #000 !important;
    font-family: "f2";
  }
}

.ad-bc-tb-dropdown::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: -7px;
  left: 26px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3c3c3c;
}

.cont-border {
  border: 1px solid #dbdbdb;
}

.ad-bc-tb-drop-down-inner-cont {
  height: 15px;
}

.ad-dash-th-hgt {
  height: 2rem;
  line-height: 2rem;
}

.ad-table-search-box {
  height: 24px;
  border: 1px solid #c5c5c5;
  input {
    color: #000;
    padding: 0 10px;
    display: flex;
    height: 100%;
    width: 100%;
    border: 0;
    &::placeholder {
      color: #c6c6c6;
    }
  }
}

.loader-icon {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgba(0, 0, 0, 0.3);
  opacity: 0.6;
  z-index: 33333;
  img {
    width: 100px;
  }
}

.ad-create-bc-drop-cont {
  input[type="checkbox"] {
    accent-color: #4aa1ac;
  }
}

// .dash-btm-cards-cont {
//   width: 49%;
// }

.ad-create-broadcast {
  input[type="date"]::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-month-field {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
  }
}

.admin-create-bc-cont {
  width: 70% !important;
  margin: auto !important;
}

.file-input {
  display: inline-block;
  text-align: left;
  background: #fff;
  width: 450px;

  position: relative;
  border-radius: 3px;
}

.file-input > [type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.file-input > .button {
  display: inline-block;
  cursor: pointer;
  background: #eee;
  padding: 8px 16px;
  border-radius: 2px;
  margin-right: 8px;
}

.file-input:hover > .button {
  background: dodgerblue;
  cursor: pointer;
  color: white;
}

.file-input > .label {
  color: #333;
  white-space: nowrap;
  opacity: 0.3;
}

.file-input.-chosen > .label {
  opacity: 1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.ad-depart {
  font-size: 13px;
  font-family: "f2";
  color: $primary1;
  border: 0;
  background: transparent;
  margin: 0 0 auto auto;
  display: flex;
}

.err-input {
  position: absolute;
  bottom: -17px;
  right: 0;
  font-size: 12px !important;
  font-family: "f2";
  color: $err !important;
}

.err-input-new-err {
  // position: absolute;
  // bottom: -17px;
  // right: 0;
  font-size: 12px !important;
  font-family: "f2";
  color: $err !important;
}
