@import "./assets/sass/fonts.scss";
@import "./assets/sass/theme.scss";

@media (max-width: 567px) {
  .top_bar {
    text-align: center;
    .logo_img img {
      width: 100% !important;
    }
  }
  .log_des {
    text-align: center;
    img {
      width: 70% !important;
    }
  }
  .card-container {
    width: 90%;
    padding: 8px !important;
  }

  .page-inner-cont {
    width: 20px !important;
    min-width: 100px !important;
    background-color: #247395;

    .page-item.active .page-link {
      z-index: 3;
      color: #fff !important;
      background-color: #247396;
      border-color: #247396;
    }
  }
}

@media (max-width: 767px) {
  .user-login-details-cont {
    margin: 0 !important;
    padding: 0 20px;
  }
  .user-login-details-backarrow-cont {
    margin: 0 !important;
  }
  .hede-top,
  .top_bar {
    min-width: 330px;
  }
  .rit-body {
    width: 100%;
    overflow-x: hidden;
  }
  .log_cent {
    height: 90% !important;

    .form_box p {
      text-align: center;
      margin: 7px 0;
      font-size: 13px;
    }
  }
  .custom-file-input {
    width: 100% !important;
  }
  .rit-ad-prod {
    position: inherit !important;
    padding: 20px;
  }
  .user-list-userlist-container {
    min-width: 330px;
    overflow: scroll;
  }
  .filter-btn,
  .map-btn {
    width: 79px !important;
    height: 35px !important;
    margin-left: 4px !important;
    font-size: 12px !important;
  }

  .css-1wvake5 {
    &.ps-collapsed {
      width: 150% !important;
      min-width: 150% !important;
      left: 0 !important;
    }
  }
  .tbale-scroll-horizontal {
    width: 97% !important;
    overflow: scroll !important;
  }

  .cert_ficate {
    .img-none {
      display: none;
    }
  }
  .prof-left {
    padding-left: 0px !important;
  }
  .left-add-user {
    margin-top: 40px;
    margin-left: 15px;
  }
  .acc-payment-cont {
    width: 94% !important;
    margin-left: 0.75rem !important;
    margin-right: auto !important;
  }
}

@media (min-width: 768px) {
  .w-sm-auto {
    width: auto !important;
  }
  .rit-body {
    width: 100%;
  }
  .account-settings-left {
    min-height: 79vh;
  }
  .lin-rit-lg-profile {
    border-right: 1px solid #c6c6c6;
  }
  .change-admin-btn {
    margin-bottom: 0 !important;
  }
  .prof-left {
    padding-left: 30px !important;
  }
  .left-add-user {
    margin-top: 40px;
    margin-left: 15px;
  }
}

@media (max-width: 991px) {
  .product-table {
    overflow: scroll;
  }
}
@media (min-width: 992px) {
  .rit-add-user {
    padding-left: 60px !important;
  }
  .left-add-user {
    margin-top: 40px;
    margin-left: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .shoe-acces-filter {
    width: 97% !important;
  }
  .dc-prod-table {
    width: 97% !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-table {
    width: 100%;
    overflow: scroll;
  }
}

@media (min-width: 1400px) {
  .lin-rit-lg-profile {
    border-right: 1px solid #c6c6c6;
  }
  .account-settings-left {
    width: 350px !important;
  }
  .prof-left {
    padding-left: 80px !important;
  }
  .rit-add-user {
    padding-left: 100px !important;
  }
  .left-add-user {
    width: 300px;
    margin-top: 40px;
    margin-left: 90px;
  }
  .user-list-userlist-container {
    width: 60% !important;
  }
  .acc-payment-cont {
    width: 46% !important;
    margin-left: 1rem !important;
    margin-right: auto !important;
  }
}
