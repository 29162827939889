$primary1: #247395;
$light-primary1: #669db5;
$light-primary2: #7ebdd9;
$light-primary3: #06415a;
$light-primary4: #f0f0f0;
$light-primary5: #d1f7ff;
$primary2: #e2b473;
$light-primary21: #dba75d;
$light-pink: #fdcbd5;
$light-pink2: #ffedf0;
$white: #fff;
$black: #000;
$background: #f2f3f9;
$hash: #555;
$hash1: #787878;
$c5: #c5c5c5;
$t6: #666;
$t3: #333;
$err: #ff0000;
